<script lang="ts" setup>
// composable
const { t } = useLang()

// meta
definePageMeta({
  layout: 'page',
})
useHead({
  title: 'Domači Piščanci',
  meta: [
    {
      name: 'description',
      content:
        'Domače piščančje meso naravne pridelave, prvovrstni fileji, hrenovke, perutničke in bedrca domače reje. Enostavno naročilo preko spleta in dostava.',
    },
  ],
})
useSeoMeta({
  title: 'Domači Piščanci',
  ogTitle: 'Domači Piščanci',
  description:
    'Domače piščančje meso naravne pridelave, prvovrstni fileji, hrenovke, perutničke in bedrca domače reje. Enostavno naročilo preko spleta in dostava.',
  ogDescription:
    'Domače piščančje meso naravne pridelave, prvovrstni fileji, hrenovke, perutničke in bedrca domače reje. Enostavno naročilo preko spleta in dostava.',
  ogImage: 'https://doechk56wu3z8.cloudfront.net/public/img_kmetija_shape.webp',
  twitterCard: 'summary_large_image',
})
</script>

<template>
  <PageWrapper>
    <PageBody>
      <PageSection class="pb-80">
        <div
          class="pt-78 relative bg-hero-landing bg-no-repeat bg-cover bg-center"
        >
          <div class="container w-full mx-auto px-4">
            <div class="max-w-4xl text-center mx-auto relative z-5">
              <div class="relative">
                <nuxt-img
                  provider="s3"
                  src="/img_certificat.webp"
                  title="Certificate"
                  class="w-42 absolute right-6 -bottom-2 md:-bottom-12"
                />
              </div>
              <h1
                class="text-h3w md:text-h1w font-bold font-serif italic leading-16 md:leading-18 text-green"
              >
                {{ $t('pages.index.heroSection.title') }}
              </h1>
              <div
                class="text-h4w md:text-h2w font-bold leading-16 md:leading-18 italic mb-6"
              >
                {{ $t('pages.index.heroSection.subtitle') }}
              </div>

              <div class="text-lg mb-6">
                {{ $t('pages.index.heroSection.description1') }}
              </div>
              <div class="text-lg mb-6">
                {{ $t('pages.index.heroSection.description2') }}
              </div>

              <div class="flex">
                <Anchor
                  to="dashboard"
                  href="dashboard"
                  class="btn-lg fill-green mx-auto mb-28 hover:no-underline ease-in-out duration-150 hover:bg-[#45994526] rounded-btn hover:text-white font-bold"
                >
                  {{ $t('buttons.submitOrder') }}
                  <font-awesome-icon
                    :icon="['fas', 'arrow-right']"
                    class="icon-right-anim"
                  />
                </Anchor>
              </div>
            </div>
          </div>
          <div class="bg_image_fade py-40 -mb-20 -mt-80 z-4 relative"></div>
        </div>
      </PageSection>
      <PageSection
        class="bg_image_fade py-40 -mb-20 -mt-80 z-4 relative"
      ></PageSection>
      <PageSection class="z-5 relative">
        <nuxt-img
          provider="s3"
          src="/img_white_curve_top.svg"
          class="w-full"
          sizes="100vw"
          width="1470"
          height="30"
          alt="White line top"
        />
        <div class="bg-white py-24">
          <div class="container w-full mx-auto px-4">
            <h2
              class="text-h3w md:text-h2w font-bold font-serif italic leading-16 md:leading-18 text-green text-center mb-16"
            >
              {{ $t('pages.index.ourAdvantages.title') }}
            </h2>

            <div
              class="grid grid-cols- md:grid-cols-2 lg:grid-cols-3 gap-6"
              data-aos="fade-up"
              data-aos-delay="250"
              data-aos-offset="200"
            >
              <CardWebsiteBenefitsCard
                :title="$t('pages.index.ourAdvantages.adventageNum1.title')"
                :description="
                  $t('pages.index.ourAdvantages.adventageNum1.description')
                "
                image="adventages/img_no_antibiotics.svg"
              />
              <CardWebsiteBenefitsCard
                :title="$t('pages.index.ourAdvantages.adventageNum2.title')"
                :description="
                  $t('pages.index.ourAdvantages.adventageNum2.description')
                "
                image="adventages/img_free_range.svg"
              />
              <CardWebsiteBenefitsCard
                :title="$t('pages.index.ourAdvantages.adventageNum3.title')"
                :description="
                  $t('pages.index.ourAdvantages.adventageNum3.description')
                "
                image="adventages/img_slovenia.svg"
              />
              <CardWebsiteBenefitsCard
                :title="$t('pages.index.ourAdvantages.adventageNum4.title')"
                :description="
                  $t('pages.index.ourAdvantages.adventageNum4.description')
                "
                image="adventages/img_delivery.svg"
              />
              <CardWebsiteBenefitsCard
                :title="$t('pages.index.ourAdvantages.adventageNum5.title')"
                :description="
                  $t('pages.index.ourAdvantages.adventageNum5.description')
                "
                image="adventages/img_wheat.svg"
              />
              <CardWebsiteBenefitsCard
                :title="$t('pages.index.ourAdvantages.adventageNum6.title')"
                :description="
                  $t('pages.index.ourAdvantages.adventageNum6.description')
                "
                image="adventages/img_chicken.svg"
              />
            </div>
          </div>
        </div>
        <nuxt-img
          provider="s3"
          src="/img_white_curve_bottom.svg"
          class="w-full"
          sizes="100vw"
          width="1470"
          height="30"
          alt="Line Bottom"
        />
      </PageSection>
      <PageSection>
        <div class="pt-40 pb-24">
          <div class="container w-full mx-auto px-4">
            <h2
              class="text-h3w md:text-h2w font-bold font-serif italic leading-16 md:leading-18 text-green text-center"
            >
              {{ $t('pages.index.products.title') }}
            </h2>
            <div
              class="text-h4w md:text-h3w font-bold leading-16 md:leading-18 italic text-center mb-16"
            >
              {{ $t('pages.index.products.subtitle') }}
            </div>

            <div
              class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              data-aos="fade-up"
              data-aos-delay="250"
              data-aos-offset="200"
            >
              <CardWebsiteChickenCard
                :title="$t('pages.products.wholeChicken.title')"
                :description="$t('pages.products.wholeChicken.description')"
                image="products/img_cel_piscanec.webp"
              />
              <CardWebsiteChickenCard
                :title="$t('pages.products.filletBreast.title')"
                :description="$t('pages.products.filletBreast.description')"
                image="products/img_prsa.webp"
              />
              <CardWebsiteChickenCard
                :title="$t('pages.products.thighs.title')"
                :description="$t('pages.products.thighs.description')"
                image="products/img_stegna.webp"
              />
            </div>
            <div
              class="text-h5w font-bold font-serif italic leading-18 text-black text-center mb-8 mt-8"
            >
              {{ $t('pages.index.products.andMore') }}
            </div>

            <div class="flex">
              <Anchor
                href="our-products"
                to="our-products"
                class="btn-lg solid-green mx-auto hover:no-underline ease-in-out duration-150 hover:text-green"
              >
                {{ $t('buttons.viewAllProducts') }}
                <font-awesome-icon
                  :icon="['fas', 'arrow-right']"
                  class="icon-right-anim"
                />
              </Anchor>
            </div>
          </div>
        </div>
      </PageSection>
      <PageSection class="py-2 md:py-20 bg_liner_baige">
        <CardWebsiteSectionOrderNow
          url-button="about-us"
          button-name="index-ordernow"
        />
      </PageSection>
      <PageSection class="my-40">
        <PageWebsiteReviews />
      </PageSection>
      <PageSection class="overflow-hidden">
        <div class="pt-40 pb-60">
          <div class="container w-full mx-auto px-4">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-14">
              <div>
                <h2
                  class="text-h3w md:text-h2w font-bold font-serif italic leading-16 md:leading-18 text-green"
                >
                  {{ $t('pages.index.aboutUs.title') }}
                </h2>
                <div
                  class="text-h4w md:text-h3w font-bold leading-16 md:leading-18 italic mb-12"
                >
                  {{ $t('pages.index.aboutUs.subtitle') }}
                </div>

                <div class="text-lg mb-6">
                  {{ $t('pages.index.aboutUs.description1') }}
                </div>
                <div class="text-lg mb-9">
                  {{ $t('pages.index.aboutUs.description2') }}
                </div>

                <div class="flex">
                  <Anchor
                    href="about-us"
                    to="about-us"
                    class="btn-lg solid-green hover:no-underline ease-in-out duration-150 hover:bg-[#45994526] rounded-btn hover:text-green"
                  >
                    {{ $t('buttons.readMore') }}
                    <font-awesome-icon
                      :icon="['fas', 'arrow-right']"
                      class="icon-right-anim"
                    />
                  </Anchor>
                </div>
              </div>
              <div class="self-center">
                <div class="relative">
                  <nuxt-img
                    provider="s3"
                    src="/img_kmetija_shape.webp"
                    title=""
                    data-aos="fade-up"
                    data-aos-delay="250"
                    data-aos-offset="200"
                    width="1550"
                    height="1028"
                    sizes="(max-width: 768px) 90vw, (max-width: 1200px) 50vw, 33vw"
                    loading="lazy"
                    alt="Kmetija Image"
                  />
                  <nuxt-img
                    provider="s3"
                    src="/img_chicken_one.svg"
                    title="Chicken"
                    class="absolute -bottom-15 right-5 md:-bottom-5 md:-right-20"
                    data-aos="fade-up"
                    data-aos-delay="250"
                    data-aos-offset="300"
                    sizes="(max-width: 640px) 50vw, (min-width: 641px) and (max-width: 1024px) 50vw, 33vw"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageSection>
      <PageSection class="pt-12 pb-10">
        <FormWebsiteContactForm />
      </PageSection>
    </PageBody>
  </PageWrapper>
</template>

<style lang="scss"></style>

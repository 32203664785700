<template>
  <h2
    class="mx-4 text-h3w md:text-h2w font-bold font-serif italic leading-16 md:leading-18 text-green text-center mb-28"
  >
    {{ $t('pages.index.reviews.title') }}
  </h2>

  <div class="max-w-7xl mx-auto relative">
    <nuxt-img
      provider="s3"
      src="/reviews/img_quotation_mark_left.svg"
      alt="Quotation mark"
      class="absolute left-8 -top-20 md:left-28 md:-top-14"
    />

    <Swiper
      :navigation="true"
      :modules="[SwiperAutoplay, SwiperEffectFade]"
      :loop="true"
      :speed="2000"
      :centered-slides="true"
      :effect="'fade'"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: true,
      }"
    >
      <SwiperSlide>
        <div
          class="p-12 bg-white rounded-btn w-full max-w-4xl mx-auto mx-6 md:mx-20"
        >
          <div class="grid md:grid-cols-12 gap-4 content-center">
            <div class="col-span-12 md:col-span-2 text-center">
              <nuxt-img
                provider="s3"
                src="/reviews/img_matej.webp"
                title="Matej"
                class="!w-24 mx-auto rounded-full"
                sizes="96px"
                width="96"
                height="98"
                loading="lazy"
              />
            </div>
            <div
              class="grid col-span-12 md:col-span-10 text-center md:text-left content-center"
            >
              <div class="text-lg mb-2 font-bold">
                {{ $t('pages.index.reviews.reviewNum1.description') }}
              </div>
              <div class="text-base italic text-green">
                {{ $t('pages.index.reviews.reviewNum1.who') }}
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div
          class="p-12 bg-white rounded-btn w-full max-w-4xl mx-auto mx-6 md:mx-20"
        >
          <div class="grid md:grid-cols-12 gap-4 content-center">
            <div class="col-span-12 md:col-span-2 text-center">
              <nuxt-img
                provider="s3"
                src="/reviews/img_anja.webp"
                title="Review Anja"
                class="!w-24 mx-auto rounded-full"
                sizes="96px"
                width="96"
                height="98"
                loading="lazy"
              />
            </div>
            <div
              class="grid col-span-12 md:col-span-10 text-center md:text-left content-center"
            >
              <div class="text-lg mb-2 font-bold">
                {{ $t('pages.index.reviews.reviewNum2.description') }}
              </div>
              <div class="text-base italic text-green">
                {{ $t('pages.index.reviews.reviewNum2.who') }}
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div
          class="p-12 bg-white rounded-btn w-full max-w-4xl mx-auto mx-6 md:mx-20"
        >
          <div class="grid md:grid-cols-12 gap-4 content-center">
            <div class="col-span-12 md:col-span-2 text-center">
              <nuxt-img
                provider="s3"
                src="/reviews/img_blaz.webp"
                title="Review Blaz"
                class="!w-24 mx-auto rounded-full"
                sizes="96px"
                width="96"
                height="98"
                loading="lazy"
              />
            </div>
            <div
              class="grid col-span-12 md:col-span-10 text-center md:text-left content-center"
            >
              <div class="text-lg mb-2 font-bold">
                {{ $t('pages.index.reviews.reviewNum3.description') }}
              </div>
              <div class="text-base italic text-green">
                {{ $t('pages.index.reviews.reviewNum3.who') }}
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
    <nuxt-img
      provider="s3"
      src="/reviews/img_quotation_mark_right.svg"
      alt="Quotation mark"
      class="absolute right-14 -bottom-20 md:right-28 md:-bottom-14"
    />
  </div>
</template>
<script setup lang="ts"></script>
<style scoped lang="scss">
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

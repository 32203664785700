<script lang="ts">
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },
})
</script>

<template>
  <div class="rounded-card bg-background p-7">
    <nuxt-img
      :src="image"
      provider="s3"
      :alt="title"
      class="mb-4 mx-auto h-40 -mb-1"
      sizes="160px"
      width="160"
      height="160"
      loading="lazy"
    />
    <h4 class="text-2xl font-bold mb-4 text-green text-center">{{ title }}</h4>
    <div class="text-base text-center">{{ description }}</div>
  </div>
</template>

<style scoped lang="scss"></style>
